import { MediaAssetProps } from "design-system/components/primitives/media-asset/media-asset";
import { MediaAssetImageProps } from "design-system/components/primitives/media-asset/media-asset-image";
import { createContentfulProps } from "../../lib/create-contentful-props";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  ImageEntry,
  VideoEntry,
  PersonEntry,
} from "frontend/contentful/schema/semantics";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { useContentful } from "frontend/hooks/use-contentful";
import { getPersonName } from "frontend/contentful/components/utils/get-person-name";

type MediaEntry = ImageEntry | VideoEntry;

export const createMediaAssetProps = createContentfulProps<
  MediaEntry,
  MediaAssetProps
>(({ entry, createEditAttributes }) => {
  const { getAsset } = useContentful();

  const asset = getAsset(entry.fields.asset);

  if (isContentType<ImageEntry>("image", entry)) {
    if (asset?.fields?.file?.url) {
      const mediaAssetImageProps: MediaAssetImageProps = {
        src: asset?.fields?.file?.url,
        alt:
          entry.fields.altText?.toLowerCase() === "na"
            ? undefined
            : entry.fields.altText,
        editAttributes: {
          src: createEditAttributes({ entry, fieldId: "asset" }),
        },
      };

      if (asset?.fields?.file?.details?.image?.width) {
        mediaAssetImageProps.width =
          asset.fields.file.details.image.width.toString();
      }

      if (asset?.fields?.file?.details?.image?.height) {
        mediaAssetImageProps.height =
          asset.fields.file.details.image.height.toString();
      }

      if (
        asset?.fields?.file?.details?.image?.width &&
        asset?.fields?.file?.details?.image?.height
      ) {
        mediaAssetImageProps.intrinsicAspectRatio = `${asset.fields.file.details.image.width}/${asset.fields.file.details.image.height}`;
      }

      return {
        image: mediaAssetImageProps,
      };
    }
  }

  if (isContentType<VideoEntry>("video", entry)) {
    if (entry.fields.externalURL) {
      return {
        embed: {
          title: entry.fields.title || "",
          src: entry.fields.externalURL,
          description: asset?.fields?.description,
          width: 16,
          height: 9,
        },
      };
    }

    if (entry.fields.kalturaId) {
      return {
        kaltura: {
          entryId: entry.fields.kalturaId,
          threePlayId: entry.fields.threePlayID,
          threePlayProjectId: entry.fields.threePlayProjectID,
          duration: entry.fields.duration,
          description: (
            <RenderContentfulRichText document={entry.fields.description} />
          ),
          caption: entry.fields.caption ? (
            <RenderContentfulRichText document={entry.fields.caption} />
          ) : entry.fields.kalturaCaption ? (
            <p>{entry.fields.kalturaCaption}</p>
          ) : undefined,
          hide: { caption: entry.fields.hideCaption ?? true },
        },
      };
    }

    if (asset?.fields?.file?.url && asset.fields.file.contentType) {
      return {
        video: {
          src: asset.fields.file.url,
          mimeType: asset.fields.file.contentType,
          description: asset?.fields?.description,
          caption: entry.fields.caption ? (
            <RenderContentfulRichText
              document={entry.fields.caption}
              disableParagraphs
            />
          ) : undefined,
          hide: { caption: entry.fields.hideCaption ?? true },
          editAttributes: {
            src: createEditAttributes({ entry, fieldId: "asset" }),
          },
        },
      };
    }
  }
});

/**
 * Includes align and caption - for embeded media within Rich Text
 */
export const createRichMediaAssetProps = createContentfulProps<
  MediaEntry,
  MediaAssetProps
>(({ entry }) => {
  const props = createMediaAssetProps(entry);

  if (isContentType<ImageEntry>("image", entry)) {
    if (props?.image) {
      if (entry.fields.caption) {
        props.image.caption = (
          <RenderContentfulRichText document={entry.fields.caption} />
        );
      }

      if (entry.fields.alignment) {
        props.image.align = `align-${entry.fields.alignment}`;

        if (
          entry.fields.alignment === "left" ||
          entry.fields.alignment === "right"
        ) {
          props.image.mobileWidth = 300;
        }
      }
    }
  }

  if (isContentType<VideoEntry>("video", entry)) {
    if (props?.video) {
      props.video.align = "align-center";
    } else if (props?.embed) {
      props.embed.align = "center";
    }
  }

  return props;
});

export const createPersonImageProps = createContentfulProps<
  PersonEntry,
  MediaAssetProps
>(({ entry }) => {
  if (entry.fields.image) {
    return createMediaAssetProps(entry.fields.image);
  } else if (entry.fields.hbsEnterpriseID) {
    const modifiedDate = entry.sys.updatedAt?.split("T")[0];
    const mediaAssetImageProps: MediaAssetImageProps = {
      src: `https://www.hbs.edu/api/headshot/${entry.fields.hbsEnterpriseID}?${modifiedDate}`,
      alt: getPersonName(entry),
    };
    return {
      image: mediaAssetImageProps,
    };
  }
});
